/** This template is used by /scripts/prebuild.js
 * to create ci stage specific environment
 */
export const environment = {
  production: true,
  hmr: false,
  TENANT_APP_PATH: '/df/',
  PUBLIC_APP_PATH: '/app/',
  API_URL: 'dev.dailyfrenzy.com',
  CURRENCY: 'USD',
  CI_COMMIT_SHA: '286757d8fb69dfd968e6474e1564456fa145f242',
};
